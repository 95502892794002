<template>
  <div class="grow center wrap">
    <form @submit.prevent="post" class="container center d-col">
      <div class="row center gap-50">
        <s-logo />
        <div class="profile" v-if="hash">
          <img
            class="pic"
            :src="link"
            alt=""
          />
        </div>
      </div>
      <h1 class="d-flex green--text font-48 text-center">
        <div class="bold">
          <span v-if="hash">Enterprise</span> Customer Portal
        </div>
      </h1>
      <div class="missingText" :class="{ textRed: message.status == 'error' }">
        <div v-if="message.errorCode">
          {{ $t("server." + message.errorCode) }}
        </div>
      </div>
      <div class="w100">
        <span class="red--text font-14">Required (*)</span>
      </div>
      <s-text-field
        block
        v-model="company"
        type="text"
        :label="'Company *'"
      ></s-text-field>
      <s-text-field
        block
        v-model="firstName"
        :label="$t('registration.first_name') + ' *'"
      ></s-text-field>
      <s-text-field
        block
        v-model="lastName"
        :label="$t('registration.last_name') + ' *'"
      ></s-text-field>
      <s-text-field block v-model="email" label="Email *"></s-text-field>
      <s-text-field
        block
        bar
        password
        v-model="password"
        type="password"
        :label="$t('registration.password') + ' *'"
      ></s-text-field>

      <s-text-field
        block
        password
        v-model="confirmPassword"
        type="password"
        :label="$t('registration.confirm_password') + ' *'"
      ></s-text-field>
      <div class="d-flex space-between w100 mt-15 center wrap" ref="terms">
        <div class="center gap-10" @click="check = !check">
          <s-checkbox v-model="check"></s-checkbox>
          <button class="green--text pl-0 font-12 pointer d-flex" type="button">
            <div>{{ $t("registration.terms") }}</div>
            <div @click="tns" class="underline ml-5">
              {{ $t("registration.tos") }}
            </div>
          </button>
        </div>
      </div>
      <s-btn type="submit" block class="green mt-15" height="40">
        {{ $t("registration.submit") }}
      </s-btn>
    </form>
    <div class="tns_container center" ref="modal_page" v-show="terms">
      <div class="tns relative" ref="modal_window" v-click-outside="closeModal">
        <div class="w100 justify-end d-flex">
          <button>
            <s-icon @click.native="closeModal" color="grey">close</s-icon>
          </button>
        </div>
        <Terms />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Terms from "./Terms";

export default {
  components: {
    Terms,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      password: "",
      confirmPassword: "",
      message: "",
      check: false,
    };
  },
  methods: {
    tns() {
      this.check = !this.check;
      this.$store.commit("modals/terms", true);
    },
    closeModal() {
      this.$store.commit("modals/terms", false);
    },
    validatename() {
      var exr = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;
      var first = this.firstName.match(exr);
      var last = this.lastName.match(exr);
      let code = "";

      if (!last) code = "invalid_lastname";
      if (!first) code = "invalid_firstname";
      if (this.lastName.length < 2) code = "min_last_name";
      if (this.firstName.length < 2) code = "min_first_name";
      if (this.lastName.length == 0) code = "enter_last_name";
      if (this.firstName.length == 0) code = "enter_first_name";
      if (this.company.length < 2) code = "companyRequired";
      if (!code) return true;
      this.message = { errorCode: code, status: "error" };
    },
    async post() {
      if (!this.validatename()) return;

      let data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        password2: this.confirmPassword,
        check: this.check,
        company: this.company,
      };
      if (this.hash) data.resellerHash = this.hash;
      let response = await this.$store.dispatch("auth/register", data);
      this.message = response;

      if (response.status == "ok") {
        let msg = { errorCode: "agree", status: "error" };
        if (!this.check) return (this.message = msg);
        await this.$store.dispatch("user/getUserInformation");
        await this.$store.dispatch("products/getProducts");
        await this.$store.dispatch("licenses/getLicenses");
        this.$router.push("/licenses");
      }
    },
    async loadLogo() {
      if (!this.hash) return;
      let res = await this.$store.dispatch("auth/getResellerLogo", this.hash);
    },
  },
  computed: {
    hash() {
      return this.$route.params.hash;
    },
    link() {
      return process.env.VUE_APP_URL + "/api/reseller/logo/" + this.hash;
    },
    ...mapState("modals", ["terms"]),
  },
  mounted() {
    this.loadLogo();
    let el = this.$refs.terms;
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 0);
  },
};
</script>


<style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.textRed {
  color: #df5151;
}

.tns_container {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 3;
}

.tns {
  max-width: 650px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
  overflow-y: scroll;
  max-height: 700px;
  height: 700px;
  max-height: 100vh;
}

.profile {
  background-color: grey;
  height: 86px;
  width: 86px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 576px) {
  .tns {
    padding: 5px 0 5px 5px;
  }
}
</style>
