<template>
  <div class="h100 vh100 d-flex d-col">
    <TopToolbar />
    <Registration />
  </div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import Registration from "@/components/Auth/Register/CustomerRegistration.vue";

export default {
  name: "Register",
  components: {
    Registration,
    TopToolbar,
  },
};
</script>

